import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBUvQ_i6oo8iap5ek-EvFpkSVV2XZUSVkk",
  authDomain: "sarya-cafe-beefreund.firebaseapp.com",
  databaseURL: "https://sarya-cafe-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sarya-cafe-beefreund",
  storageBucket: "sarya-cafe-beefreund.appspot.com",
  messagingSenderId: "376213924892",
  appId: "1:376213924892:web:9dc34651e7f68c525a83d9",
  measurementId: "G-TQDJ7LF5TM"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
